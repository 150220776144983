import { FC, useState, useEffect } from "react";
import {
  Form,
  notification,
  Drawer,
  InputText,
  FileUpload,
  Button,
} from "components";
import { useMutation } from "hooks";
import { Storage } from "utils";
import { QueryKey, Document } from "types";

interface DocumentDrawerProps {
  open: boolean;
  companyID: number;
  document: Document | null;
  queryKey: QueryKey;
  onClose: () => void;
}

const DocumentDrawer: FC<DocumentDrawerProps> = ({
  open,
  companyID,
  document,
  queryKey,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [loading, toggleLoading] = useState(false);
  const { mutateAsync, isPending } = useMutation<
    string,
    {
      fileID: string;
      description: string;
      companyID: number;
    }
  >({
    apiName: "company",
    path: `/${companyID}/documents${document ? `/${document.id}` : ""}`,
    method: document ? "patch" : "post",
    invalidateQueries: [queryKey],
    onSuccess: () => onClose(),
  });

  useEffect(() => {
    if (document) {
      form.setFieldsValue(document);
    }
  }, [document, form]);

  return (
    <Drawer
      open={open}
      destroyOnClose
      title={`${document ? "Update" : "Add"} a document`}
      onClose={onClose}
      footer={
        <Button
          type="primary"
          loading={isPending || loading}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      }
    >
      <Form
        form={form}
        preserve={false}
        layout="vertical"
        initialValues={document || {}}
        onFinish={async ({ fileUpload, ...rest }) => {
          try {
            const [{ name, originFileObj }] = fileUpload;

            toggleLoading(true);

            const promises = [
              Storage.uploadData({
                key: `company/${companyID}/${name}`,
                data: originFileObj,
              }),
            ];

            if (document) {
              promises.push(
                Storage.del({
                  key: document.fileID,
                }),
              );
            }

            const [{ key }] = await Promise.all(promises);

            await mutateAsync({
              fileID: key,
              ...rest,
            });
          } catch (e: any) {
            notification.error({
              message: e.message,
            });
          }

          toggleLoading(false);
        }}
      >
        <InputText required name="description" label="Description" />
        <FileUpload
          required
          maxCount={1}
          fileID={document?.fileID}
          listType="picture"
          name="fileUpload"
          label="File"
        />
      </Form>
    </Drawer>
  );
};

export default DocumentDrawer;
