import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { Types, asyncActions } from "./duck";

const initialState: Types.State = {
  loading: false,
  authenticating: true,
  backoffice: null,
  account: null,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(asyncActions.authenticateUser.pending, (state) => {
      state.authenticating = true;
    });

    builder.addCase(asyncActions.authenticateUser.rejected, (state) => {
      state.authenticating = false;
    });

    builder.addCase(asyncActions.logOut.fulfilled, () => {
      return {
        ...initialState,
        authenticating: false,
      };
    });

    const pendingMatcher = isAnyOf(
      asyncActions.login.pending,
      asyncActions.logOut.pending,
    );
    const rejectedMatcher = isAnyOf(
      asyncActions.login.rejected,
      asyncActions.logOut.rejected,
    );

    builder.addMatcher(
      isAnyOf(
        asyncActions.login.fulfilled,
        asyncActions.authenticateUser.fulfilled,
      ),
      (state, { payload }) => {
        state.authenticating = false;
        state.loading = false;
        state.backoffice = payload.backoffice;
      },
    );

    builder.addMatcher(pendingMatcher, (state) => {
      state.loading = true;
    });

    builder.addMatcher(rejectedMatcher, (state) => {
      state.loading = false;
    });
  },
});

export const { reducer, actions } = auth;
