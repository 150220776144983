import { useQueries } from "@tanstack/react-query";
import * as API from "aws-amplify/api";
import { operations, Types } from "./duck";

const useQueriesRequest = <TQueryFnData>({
  queries,
}: Types.UseQueriesArgs<TQueryFnData>) => {
  return useQueries({
    queries: queries.map((item) => ({
      queryKey: item.queryKey,
      enabled: item.enabled,
      select: item.select,
      queryFn: async ({ signal }) => {
        const { cancel, response } = API.get({
          apiName: item.apiName,
          path: item.path || "",
          options: item.options,
        });

        signal.addEventListener("abort", () => {
          cancel();
        });

        return operations.queryFnWrapper<TQueryFnData>(response);
      },
    })),
  });
};

export default useQueriesRequest;
