import { FC } from "react";
import { Tag } from "antd";
import { COLORS } from "consts";
import { Status } from "types";
import classNames from "classnames";
import { createUseStyles } from "react-jss";

interface StatusTagProps extends Status {
  fullWidth?: boolean;
}

const STATUS_COLORS: Record<string, string> = {
  1: COLORS.orange[4],
  2: COLORS.blue[4],
  3: COLORS.magenta[4],
  4: COLORS.green[4],
  5: COLORS.gray[4],
  6: COLORS.lime[5],
};

const useStyles = createUseStyles({
  tag: {
    textAlign: "center",
  },
  fullWidth: {
    width: "100%",
  },
});

const StatusTag: FC<StatusTagProps> = ({ id, name, fullWidth }) => {
  const classes = useStyles();

  return (
    <Tag
      className={classNames(classes.tag, {
        [classes.fullWidth]: fullWidth,
      })}
      color={STATUS_COLORS[id]}
    >
      {name}
    </Tag>
  );
};

export default StatusTag;
