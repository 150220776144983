export default {
  // dashboard
  dashboard: "/dashboard",
  // login
  login: "/login",

  // company
  company: {
    main: "/company",
    list() {
      return `${this.main}/list`;
    },
    documents() {
      return `${this.main}/documents`;
    },
    view(id?: number) {
      return `${this.main}/${id || ":id"}`;
    },
  },
};
