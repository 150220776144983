import { FC } from "react";
import { useCompanyHolders } from "hooks";
import { DATE_FORMAT } from "consts";
import { TabProps } from "../../types";
import { ColumnFilterInputTypes } from "types";
import dayjs from "dayjs";
import HoldersAddress from "../holders-address";
import { FormattedMessage, Typography, ExtendedTable } from "components";

const Officeholders: FC<TabProps> = ({ company }) => {
  const { data, isLoading } = useCompanyHolders({
    companyID: company.id,
    subRoute: "officeholders",
  });

  return (
    <>
      <Typography.Title level={5}>
        <FormattedMessage id="common.officeholders" />
      </Typography.Title>

      <ExtendedTable
        loading={isLoading}
        dataSource={data}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (record) => (
            <HoldersAddress addressID={record.residentAddressID} />
          ),
        }}
        columns={[
          {
            title: "common.individual",
            dataIndex: "individual",
          },
          {
            title: "common.office",
            dataIndex: "office",
          },
          {
            title: "common.placeOfBirthCity",
            dataIndex: "placeOfBirthCity",
          },
          {
            title: "common.placeOfBirthCountry",
            dataIndex: "placeOfBirthCountry",
          },
          {
            title: "common.dateOfBirth",
            dataIndex: "dateOfBirth",
            filterInputType: ColumnFilterInputTypes.DATE_RANGE,
            render: ({ value }) => dayjs(value).format(DATE_FORMAT),
          },
        ]}
      />
    </>
  );
};

export default Officeholders;
