import { useQuery, DefaultError } from "@tanstack/react-query";
import { operations, Types } from "./duck";
import { STAGE } from "consts";
import { get } from "aws-amplify/api";

const useQueryRequest = <TQueryFnData, TData = TQueryFnData>({
  apiName,
  path = "",
  queryKey,
  enabled,
  options,
  select,
}: Types.QueryArgs<TQueryFnData, TData>) => {
  return useQuery<TQueryFnData, DefaultError, TData>({
    queryKey,
    enabled,
    select,
    retry: STAGE !== "uat" && STAGE !== "prod" ? 2 : 0,
    queryFn: async ({ signal }) => {
      // use raw "get" to access "cancel" method
      const { cancel, response } = get({
        apiName,
        path,
        options,
      });

      signal.addEventListener("abort", () => {
        cancel();
      });

      return operations.queryFnWrapper<TQueryFnData>(response);
    },
  });
};

export default useQueryRequest;
