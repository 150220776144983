import * as API from "aws-amplify/api";
import { notification } from "antd";
import { RestApiResponse } from "types";

export const queryFnWrapper = async <TData>(
  operation: Promise<RestApiResponse | Omit<RestApiResponse, "body">>,
) => {
  try {
    const resp = await operation;

    if (!("body" in resp)) {
      return resp as TData;
    }

    return (await resp.body.json()) as TData;
  } catch (e: any) {
    let { message } = e;

    if (API.isCancelError(e)) {
      throw new Error(message);
    }

    if (e instanceof API.ApiError && e.response?.body) {
      const parsed = JSON.parse(e.response.body);

      message = parsed.error || parsed.message;
    }

    notification.error({
      message,
    });

    throw new Error(message);
  }
};
