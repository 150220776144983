import { Form, Select } from "antd";
import * as Types from "../types";
import { removeExSpaces } from "utils";
import { SelectOption } from "types";
import _isString from "lodash/isString";

const { Option } = Select;

const defaultFilterOption = (input: string, option?: SelectOption) => {
  if (typeof option?.children === "string") {
    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) !== -1;
  }

  return (
    option?.children?.props?.children
      ?.toLowerCase()
      .indexOf(input.toLowerCase()) !== -1
  );
};

const Dropdown = <TOption,>({
  options = [],
  placeholder,
  allowClear = false,
  getOptionProps,
  style = { width: "100%" },
  innerRef,
  filterOption = defaultFilterOption,
  ...props
}: Types.InnerSelectProps<TOption>) => {
  return (
    <Select
      {...props}
      ref={innerRef}
      style={style}
      placeholder={placeholder}
      allowClear={allowClear}
      showSearch
      filterOption={filterOption}
    >
      {options.map((option, index) => {
        const { value, children, ...rest } = getOptionProps(option, index);

        return (
          <Option key={value} value={value} {...rest}>
            {_isString(children) ? removeExSpaces(children) : children}
          </Option>
        );
      })}
    </Select>
  );
};

const InputSelect = <Option,>({
  label,
  name,
  isFormItem = true,
  preserve,
  required,
  rules = [],
  innerRef,
  noStyle,
  className,
  ...props
}: Types.InputSelectProps<Option>) => {
  const S = <Dropdown {...props} innerRef={innerRef} />;

  if (!isFormItem) {
    return S;
  }

  return (
    <Form.Item
      label={label}
      name={name}
      preserve={preserve}
      noStyle={noStyle}
      className={className}
      rules={[
        {
          required,
          message: 'Can"t be blank',
        },
        ...rules,
      ]}
    >
      {S}
    </Form.Item>
  );
};

export default InputSelect;
