import { FC } from "react";
import { useCompanyHolders } from "hooks";
import {
  FormattedMessage,
  Typography,
  ExtendedTable,
  ExtendedDescriptions,
} from "components";
import HoldersAddress from "../holders-address";
import { formatToCurrency } from "utils";
import { ShareCapital, Shareholder } from "types";
import { hooks } from "../../duck";
import { TabProps } from "../../types";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../../consts";

const ShareHoldings: FC<TabProps> = ({ company }) => {
  const { data = [], isLoading } = useCompanyHolders({
    companyID: company.id,
    subRoute: "shareholders",
  });
  const { data: shareCapital, isLoading: shareCapitalLoading } =
    hooks.useShareCapital(company.id);

  return (
    <>
      <Typography.Title level={5}>
        <FormattedMessage id="common.shareholders" />
      </Typography.Title>

      <ExtendedTable<Shareholder>
        loading={isLoading}
        dataSource={data}
        expandable={{
          expandRowByClick: true,
          rowExpandable: (record) => record.typeID === 1,
          expandedRowRender: (record) => (
            <ExtendedDescriptions
              column={4}
              items={[
                {
                  label: "common.address",
                  value: (
                    <HoldersAddress addressID={record.residentAddressID} />
                  ),
                },
                {
                  label: "common.placeOfBirthCity",
                  value: record.placeOfBirthCity,
                },
                {
                  label: "common.placeOfBirthCountry",
                  value: record.placeOfBirthCountry,
                },
                {
                  label: "common.dateOfBirth",
                  value: dayjs(record.dateOfBirth).format(DATE_FORMAT),
                },
              ]}
            />
          ),
        }}
        columns={[
          {
            title: "common.id",
            dataIndex: "id",
          },
          {
            title: "common.name",
            dataIndex: "individual",
            render: ({ record }: any) => record.individual || record.company,
          },
          {
            title: "common.type",
            dataIndex: "type",
          },
          {
            title: "common.beneficiallyHeld",
            dataIndex: "beneficiallyHeld",
            render: ({ value }) => value && "+",
          },
          {
            title: "company.shareClass",
            dataIndex: "shareClass",
          },
          {
            title: "company.sharesIssued",
            dataIndex: "sharesIssued",
          },
        ]}
      />

      <Typography.Title level={5}>
        <FormattedMessage id="common.shareCapital" />
      </Typography.Title>

      <ExtendedTable<ShareCapital>
        loading={shareCapitalLoading}
        dataSource={shareCapital}
        columns={[
          {
            title: "common.code",
            dataIndex: "shareCode",
          },
          {
            title: "company.shareClass",
            dataIndex: "shareClass",
          },
          {
            title: "common.quantity",
            dataIndex: "quantity",
          },
          {
            title: "common.price",
            dataIndex: "price",
          },
          {
            title: "common.total",
            dataIndex: "price",
            render: ({ record }) =>
              formatToCurrency(record.quantity * record.price),
          },
        ]}
      />
    </>
  );
};

export default ShareHoldings;
