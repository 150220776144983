import { useState, FC, useCallback } from "react";
import { Divider, Card, Typography } from "antd";
import { DashboardHOCState } from "types";

interface DashboardHOC<P> {
  Component: FC<P>;
  title: string;
  subTitle?: string;
}

export const dashboardHOC =
  <P,>({ Component, title, subTitle }: DashboardHOC<P>) =>
  (props: P) => {
    const [state, setState] = useState<DashboardHOCState>({
      title,
      subTitle,
      extra: [],
    });

    const getConfig = useCallback((newValues: Partial<DashboardHOCState>) => {
      setState((prevState) => ({
        ...prevState,
        ...newValues,
      }));
    }, []);

    return (
      <Card title={title} extra={state.extra}>
        {state.subTitle && (
          <>
            <Typography.Title level={5}>{state.subTitle}</Typography.Title>
            <Divider />
          </>
        )}
        <Component {...props} getConfig={getConfig} />
      </Card>
    );
  };
