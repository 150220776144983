import { FC, useState } from "react";
import { ExtendedTable, Row, Button, Icons } from "components";
import { DocumentDrawer } from "./components";
import { useQuery } from "hooks";
import { getQueryKey } from "utils";
import { Document } from "types";

interface DocumentsProps {
  companyID: number;
}

const Documents: FC<DocumentsProps> = ({ companyID }) => {
  const [drawerOpen, toggleDrawer] = useState(false);
  const [document, setDocument] = useState<Document | null>(null);
  const queryKey = getQueryKey("companyDocuments", companyID);
  const { data: documents, isLoading } = useQuery<Document[]>({
    apiName: "company",
    path: `/${companyID}/documents`,
    queryKey,
  });

  return (
    <>
      <DocumentDrawer
        companyID={companyID}
        queryKey={queryKey}
        document={document}
        open={drawerOpen || !!document}
        onClose={() => {
          toggleDrawer(false);
          setDocument(null);
        }}
      />
      <Row justify="end">
        <Button
          type="primary"
          icon={<Icons.PlusOutlined />}
          onClick={() => toggleDrawer(true)}
        />
      </Row>
      <ExtendedTable<Document>
        loading={isLoading}
        dataSource={documents}
        columns={[
          {
            title: "common.id",
            dataIndex: "id",
            render: ({ record }) => (
              <Button
                type="link"
                onClick={() => {
                  setDocument(record);
                }}
              >
                {record.id}
              </Button>
            ),
          },
          {
            ellipsis: true,
            title: "common.file",
            dataIndex: "fileID",
          },
          {
            title: "common.description",
            dataIndex: "description",
          },
        ]}
      />
    </>
  );
};

export default Documents;
