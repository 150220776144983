import { InitialData } from "./types";
import { Backoffice } from "types";
import { API } from "utils";

export const getInitialData = async (
  cognitoID: string,
): Promise<InitialData> => {
  const backoffice: Backoffice = await API.get({
    apiName: "backoffice",
    path: `/${cognitoID}`,
  });

  return {
    backoffice,
  };
};
