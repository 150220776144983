import { FC } from "react";
import { Tabs, PreLoader } from "components";
import * as LC from "./components";
import { getQueryKey } from "utils";
import { viewHOC } from "hocs";
import { Company, ViewHOCChildProps } from "types";

const ViewCompany: FC<ViewHOCChildProps<Company>> = ({ record: company }) => {
  return (
    <Tabs
      items={[
        {
          label: "Summary",
          key: "Summary",
          children: <LC.Summary company={company} />,
        },
        {
          label: "Address",
          key: "Address",
          children: <LC.Address company={company} />,
        },
        {
          label: "Documents",
          key: "Documents",
          children: <LC.Documents companyID={company.id} />,
        },
        {
          label: "Documents Distribution",
          key: "DocumentsDistribution",
          children: (
            <LC.DocumentsDistribution distributionID={company.distributionID} />
          ),
        },
        {
          label: "Options",
          key: "Options",
          children: <LC.Options company={company} />,
        },
        {
          label: "Officeholders",
          key: "Officeholders",
          children: <LC.Officeholders company={company} />,
        },
        {
          label: "Shareholders",
          key: "Shareholders",
          children: <LC.ShareHoldings company={company} />,
        },
      ]}
    />
  );
};

export default viewHOC({
  Component: ViewCompany,
  skeleton: <PreLoader />,
  queryKeyFn: ({ params }) => getQueryKey("company", params.id),
  apiName: "company",
  pathFn: ({ params }) => `/${params.id}/details`,
});
