import { Amplify } from "aws-amplify";
import { ENDPOINTS } from "consts";

const {
  REACT_APP_REGION,
  REACT_APP_BUCKET,
  REACT_APP_API_URL,
  REACT_APP_USER_POOL_ID,
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_USER_POOL_CLIENT_ID,
} = process.env as Record<string, string>;

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        allowGuestAccess: true,
        identityPoolId: REACT_APP_IDENTITY_POOL_ID,
        userPoolId: REACT_APP_USER_POOL_ID,
        userPoolClientId: REACT_APP_USER_POOL_CLIENT_ID,
      },
    },
    Storage: {
      S3: {
        region: REACT_APP_REGION,
        bucket: REACT_APP_BUCKET,
      },
    },

    API: {
      REST: Object.keys(ENDPOINTS).reduce(
        (prev, curr) => {
          prev[curr] = {
            region: REACT_APP_REGION,
            endpoint: `${REACT_APP_API_URL}/${curr}`,
          };

          return prev;
        },
        {} as Record<string, any>,
      ),
    },
  });
};
