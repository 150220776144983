import * as Types from "./types";
import * as API from "aws-amplify/api";
import { Operation, RestApiResponse } from "types";

const wrapper = (operation: Operation<RestApiResponse>) => {
  return operation.response
    .then((r) => r.body.json())
    .catch((e) => {
      if (e instanceof API.ApiError && e.response?.body) {
        throw new Error(JSON.parse(e.response.body)?.error || e.message);
      }

      throw new Error(e.message);
    });
};

const typedAPI: Types.IAPI = {
  get: ({ apiName, path = "", options }) =>
    wrapper(API.get({ apiName, path, options })),
  post: ({ apiName, path = "", options }) =>
    wrapper(API.post({ apiName, path, options })),
  put: ({ apiName, path = "", options }) =>
    wrapper(API.put({ apiName, path, options })),
  patch: ({ apiName, path = "", options }) =>
    wrapper(API.patch({ apiName, path, options })),
  del: ({ apiName, path = "", options }) =>
    API.del({ apiName, path, options }).response,
};

export default typedAPI;
