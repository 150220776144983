import { FC } from "react";
import { Form, FormItemProps, Checkbox, CheckboxProps } from "antd";
import { FormattedMessage } from "react-intl";
import { XOR } from "types";

interface InputCheckboxProps extends FormItemProps {
  label?: string;
  labelPlacement?: "formItem" | "children";
}

interface WithoutForm
  extends FormItemProps,
    Pick<CheckboxProps, "checked" | "onChange"> {
  label?: string;
  formItem: boolean;
}

const InputCheckbox: FC<XOR<InputCheckboxProps, WithoutForm>> = ({
  name,
  required,
  label,
  formItem,
  labelPlacement = "children",
  checked,
  onChange,
  ...props
}) => {
  const translatedLabel = label && <FormattedMessage id={label} />;

  if (!formItem) {
    return (
      <Checkbox onChange={onChange} checked={checked}>
        {translatedLabel}
      </Checkbox>
    );
  }

  return (
    <Form.Item
      {...props}
      name={name}
      label={labelPlacement === "formItem" ? translatedLabel : undefined}
      valuePropName="checked"
      rules={[
        {
          required,
          validator: (rule, value) => {
            // for some reason antd doesn't see this for checkbox
            if (!required) {
              return Promise.resolve();
            }

            if (value === undefined || value === null) {
              return Promise.reject(
                <FormattedMessage id="validation.required" />,
              );
            }

            return Promise.resolve();
          },
        },
      ]}
    >
      <Checkbox>{labelPlacement === "children" && translatedLabel}</Checkbox>
    </Form.Item>
  );
};

export default InputCheckbox;
