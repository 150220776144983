import { configureStore } from "@reduxjs/toolkit";
import { authReducer, appStateReducer } from "./slices";
import { createLogger } from "redux-logger";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appState: appStateReducer,
  },
  // TODO investigate
  // eslint-disable-next-line
  // @ts-ignore
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === "development") {
      const logger = createLogger({
        collapsed: true,
      });

      return getDefaultMiddleware().concat(logger);
    }

    return getDefaultMiddleware();
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
