import * as Pages from "pages";
import { routes } from "configs";
import { dashboardHOC } from "hocs";

export const SECURED_ROUTES = [
  {
    path: routes.dashboard,
    Component: Pages.Dashboard,
  },
  {
    path: routes.company.list(),
    Component: dashboardHOC({
      Component: Pages.Company.List,
      title: "Company",
    }),
  },
  {
    path: routes.company.view(),
    Component: dashboardHOC({
      Component: Pages.Company.View,
      title: "Details",
    }),
  },
];
