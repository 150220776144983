import { FC } from "react";
import { useQuery } from "hooks";
import { getQueryKey, removeExSpaces } from "utils";
import { Address } from "types";
import { Typography, PreLoader } from "components";

interface HoldersAddressProps {
  addressID?: number;
}

const HoldersAddress: FC<HoldersAddressProps> = ({ addressID }) => {
  const { data: address, isLoading } = useQuery<Address>({
    apiName: "address",
    path: `/${addressID}`,
    enabled: !!addressID,
    queryKey: getQueryKey("officeholderAddress", addressID),
  });

  if (isLoading) {
    return <PreLoader />;
  }

  if (!address) {
    return null;
  }

  const addressString = removeExSpaces(
    `${address.country} ${address.state} ${address.streetType} ${address.streetName} ${address.streetNumber} ${address.postcode}`,
  );

  return <Typography.Paragraph>{addressString}</Typography.Paragraph>;
};

export default HoldersAddress;
