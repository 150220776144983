import { useEffect } from "react";
import flatten from "flat";
import { ThemeProvider } from "react-jss";
import { BrowserRouter, PreLoader } from "components";
import { authAsyncActions } from "configs";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "hooks";
import translations from "./translations";
import Layout from "layout";

const theme = {
  label_font_weight: 500,
};

const App = () => {
  const dispatch = useDispatch();

  const { authenticating } = useSelector((state) => ({
    authenticating: state.auth.authenticating,
  }));

  useEffect(() => {
    dispatch(authAsyncActions.authenticateUser());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale="en" messages={flatten(translations.en)}>
        <BrowserRouter>
          {authenticating ? <PreLoader size="large" /> : <Layout />}
        </BrowserRouter>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
