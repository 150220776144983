import * as Storage from "aws-amplify/storage";
import { IStorage } from "./types";

const typedStorage: IStorage = {
  getURL(config) {
    return Storage.getUrl(config);
  },
  getProperties(config) {
    return Storage.getProperties(config);
  },
  downloadData(config) {
    return Storage.downloadData(config);
  },
  uploadData(config) {
    return Storage.uploadData({
      ...config,
      options: {
        ...config.options,
        contentType: config.data.type,
      },
    }).result;
  },
  del(config) {
    return Storage.remove(config);
  },
};

export default typedStorage;
