import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { Auth } from "utils";
import { InitialData } from "./types";
import { getInitialData } from "./operations";

export const authenticateUser = createAsyncThunk<InitialData>(
  "AUTHENTICATE_USER",
  () =>
    Auth.fetchAuthSession().then(({ userSub: cognitoID }) => {
      if (!cognitoID) {
        return Promise.reject();
      }

      return getInitialData(cognitoID);
    }),
);

export const login = createAsyncThunk<
  InitialData,
  { email: string; password: string }
>("LOGIN", async ({ email, password }) => {
  try {
    await Auth.signIn({
      username: email.toLowerCase(),
      password,
    });

    const { userId } = await Auth.getCurrentUser();

    return getInitialData(userId);
  } catch (e: any) {
    notification.error({
      message: e.message,
    });

    return Promise.reject();
  }
});

export const logOut = createAsyncThunk("SIGN_OUT", () => Auth.signOut());
