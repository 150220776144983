import { FC } from "react";
import { ExtendedTable, StatusTag } from "components";
import { NavLink } from "react-router-dom";
import { routes } from "configs";
import { DATE_TIME_FORMAT } from "consts";
import dayjs from "dayjs";
import { getQueryKey } from "utils";
import _uniqBy from "lodash/uniqBy";
import { useQuery } from "hooks";
import { ColumnFilterInputTypes, Company } from "types";

const CompanyTable: FC = () => {
  const { data, isLoading } = useQuery<Company[]>({
    apiName: "company",
    path: "/list",
    queryKey: getQueryKey("companyList"),
  });

  return (
    <ExtendedTable<Company>
      loading={isLoading}
      dataSource={data}
      columns={[
        {
          title: "common.id",
          dataIndex: "id",
          render: ({ value: id }) => (
            <NavLink to={routes.company.view(id)}>{id}</NavLink>
          ),
        },
        {
          title: "common.name",
          dataIndex: "name",
        },
        {
          title: "common.status",
          dataIndex: "statusID",
          filters: _uniqBy(data, "statusID").map((item) => ({
            value: item.statusID,
            text: item.status,
          })),
          onFilter: (value, record) =>
            value.toString() === record.statusID.toString(),
          render: ({ record: { statusID, status } }) => (
            <StatusTag id={statusID} name={status} />
          ),
        },
        {
          title: "date.createdAt",
          dataIndex: "createdAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => dayjs(value).format(DATE_TIME_FORMAT),
        },
        {
          title: "date.submittedAt",
          dataIndex: "submittedAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => value && dayjs(value).format(DATE_TIME_FORMAT),
        },
      ]}
    />
  );
};

export default CompanyTable;
