import { FC } from "react";
import { createUseStyles } from "react-jss";
import { COLORS } from "consts";
import { Card } from "components";
import classNames from "classnames";

interface OptionsCardProps {
  title: string;
  description: string;
  selected: boolean;
}

const useStyles = createUseStyles<any, Pick<OptionsCardProps, "selected">>({
  card: (props) => ({
    width: 240,
    transition: ".3s",
    "&.ant-card-hoverable:hover": {
      boxShadow: `0 5px 12px 4px ${
        props.selected ? COLORS.blue[2] : "transparent"
      }`,
    },
  }),
  selected: (props) => ({
    boxShadow: `0 5px 12px 4px ${
      props.selected ? COLORS.blue[2] : "transparent"
    }`,
  }),
});

const OptionsCard: FC<OptionsCardProps> = ({
  selected,
  title,
  description,
}) => {
  const classes = useStyles({ selected });

  return (
    <Card
      className={classNames(classes.card, {
        [classes.selected]: selected,
      })}
      cover={
        <img
          alt="example"
          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
        />
      }
    >
      <Card.Meta title={title} description={description} />
    </Card>
  );
};

export default OptionsCard;
