import { FC, MutableRefObject } from "react";
import { Form, FormItemProps, Input, InputRef, InputProps } from "antd";

interface InputEmailProps extends FormItemProps {
  required?: boolean;
  isDuplicate?: boolean;
  inputProps?: InputProps;
  label?: string;
  innerRef?: MutableRefObject<InputRef | null>;
}

const InputEmail: FC<InputEmailProps> = ({
  required = false,
  label = "Email",
  name = "email",
  isDuplicate,
  inputProps = {},
  innerRef,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required,
          message: "Can't be blank",
        },
        {
          type: "email",
          message: "Email is invalid",
        },
        {
          validator() {
            return isDuplicate
              ? Promise.reject("Email already exists")
              : Promise.resolve();
          },
        },
      ]}
    >
      <Input {...inputProps} ref={innerRef} autoComplete="new-password" />
    </Form.Item>
  );
};

export default InputEmail;
