import { ENDPOINTS } from "consts";
import { ReactNode } from "react";

import {
  QueryKey,
  DefaultError as DefaultTanstackError,
  InfiniteData,
} from "@tanstack/react-query";

export type { TableColumnType } from "antd";

export interface Address {
  countryID: number;
  country: string;
  streetTypeID: number;
  streetType: string;
  id: number;
  postcode: string | null;
  state: string | null;
  streetName: string | null;
  streetNumber: string | null;
  suburb: string | null;
  unitNumber: string | null;
  boxTypeID: number | null;
  boxType: string | null;
  boxNumber: string | null;
}

export interface RcFile extends File {
  uid: string;
}

export type { Params } from "react-router-dom";

export type { QueryKey, DefaultTanstackError, InfiniteData };

export type ApiNames = keyof typeof ENDPOINTS;

export { CognitoUser } from "amazon-cognito-identity-js";

export interface ApiArgs {
  apiName: ApiNames;
  path?: string;
  options?: {
    headers?: Record<string, string>;
    queryParams?: Record<string, any>;
    body?: Record<string, any>;
  };
}

export interface CompanyDocumentDistribution {
  description: string;
  id: number;
  type: string;
  price: number;
}

/**
 * Type representing an operation that can be canceled.
 *
 * @internal
 */
export interface Operation<Response> {
  response: Promise<Response>;
  cancel(abortMessage?: string): void;
}

export type DocumentType =
  | null
  | boolean
  | number
  | string
  | DocumentType[]
  | { [prop: string]: DocumentType };

interface ResponsePayload {
  blob(): Promise<Blob>;
  json(): Promise<DocumentType | Error>;
  text(): Promise<string>;
}

/**
 * Basic response type of REST API.
 *
 * @internal
 */
export interface RestApiResponse {
  body: ResponsePayload;
  statusCode: number;
  headers: Record<string, string>;
}

export interface Client {
  accountID: number;
  cognitoID: string;
  createdAt: string;
  currentAddressID: number | null;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  stripeID: string | null;
}

export interface Backoffice {
  id: number;
  cognitoID: string;
  createdAt: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface DashboardHOCState {
  title: string;
  subTitle?: string;
  extra?: any;
}

export interface Personal {
  dateOfBirth: Date | null;
  email: string | null;
  firstName: string | null;
  id: number;
  individualID: number | null;
  lastName: string | null;
  middleName: string | null;
  nationalityID: number | null;
  placeOfBirthCity: string | null;
  placeOfBirthCountryID: number | null;
}

export interface Officeholder {
  id: number;
  ceaseDate: Date | null;
  companyID: number | null;
  individualID: number | null;
  residentAddressID: number;
  startDate: Date;
  typeID: number;
}

export interface Shareholder extends Officeholder {
  beneficiallyHeld: number;
  shareClassID: number;
  sharesIssued: number;
}

export interface Status {
  id: number;
  name: string;
}

export interface CompanyIncorporation {
  id: number;
  companyID: number;
  distributionID: number;
  nameIdentical: number;
  nameReserved: number;
  domainName: boolean;
  registeredAgentPlan: boolean;
}

export interface Company
  extends Omit<CompanyIncorporation, "id" | "companyID"> {
  acn: string | null;
  clientCognitoID: string;
  client: string;
  createdAt: Date | null;
  id: number;
  jurisdictionID: number;
  jurisdiction: string;
  name: string;
  principalPlaceOfBusinessID: number | null;
  registeredAt: Date | null;
  registeredOfficeID: number | null;
  removedAt: Date | null;
  selfManagedSuperFund: number;
  shareholderID: number | null;
  statusID: number;
  status: string;
  submittedAt: Date | null;
  typeID: number;
  type: string;
  updatedAt: Date | null;
}

export interface SelectOption {
  children: any;
  value: any;
}

export interface ViewHOCChildProps<TRecord> {
  record: TRecord;
  queryKey: QueryKey;
}

export interface ExtendedDescriptionItem {
  label: string;
  translate?: boolean;
  value: ReactNode;
}

export enum ColumnFilterInputTypes {
  DATE_RANGE = "DATE_RANGE",
  INPUT = "INPUT",
  SELECT = "SELECT",
}

export interface ShareCapital {
  companyID: number;
  id: number;
  price: number;
  quantity: number;
  shareClassID: number;
  shareClass: string;
  shareCode: string;
}

export interface Account {
  id: number;
  accountName: string;
}

export interface Document {
  companyID: number | null;
  createdAt: Date;
  description: string;
  fileID: string;
  id: number;
}

export interface Individual {
  clientID: number;
  deleted: number;
  id: number;
  personalID: number;
  residentAddressID: number;
}

export interface QueryKeys {
  company: "company";
  companyList: "companyList";
  holdersAddress: "holdersAddress";
  companyShareCapital: "companyShareCapital";
  companyDocuments: "companyDocuments";
  companyDocumentsDistribution: "companyDocumentsDistribution";
  officeholderAddress: "officeholderAddress";
  officeholders: "officeholders";
  shareholders: "shareholders";
  registeredOffice: "registeredOffice";
  principalPlaceOfBusiness: "principalPlaceOfBusiness";
}

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
// eslint-disable-next-line
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;
