import { FC } from "react";
import { CompanyDocumentDistribution } from "types";
import { useQuery } from "hooks";
import { getQueryKey, formatToCurrency } from "utils";
import { Flex, Skeleton, ExtendedCard } from "components";

interface DocumentsDistributionProps {
  distributionID: number;
}

const DocumentsDistribution: FC<DocumentsDistributionProps> = ({
  distributionID,
}) => {
  const { data = [], isLoading } = useQuery<CompanyDocumentDistribution[]>({
    apiName: "company",
    path: "/document-distributions",
    queryKey: getQueryKey("companyDocumentsDistribution"),
  });

  return (
    <Flex gap={14} justify="space-around" wrap="wrap">
      {isLoading ? (
        <>
          <Flex vertical gap={14}>
            <Skeleton.Image active style={{ width: "100%", height: "150px" }} />
            <Skeleton.Input active style={{ width: "auto" }} />
          </Flex>
          <Flex vertical gap={14}>
            <Skeleton.Image active style={{ width: "100%", height: "150px" }} />
            <Skeleton.Input active style={{ width: "auto" }} />
          </Flex>
          <Flex vertical gap={14}>
            <Skeleton.Image active style={{ width: "100%", height: "150px" }} />
            <Skeleton.Input active style={{ width: "auto" }} />
          </Flex>
        </>
      ) : (
        data?.map((item) => (
          <ExtendedCard
            key={item.id}
            title={item.type}
            description={`${item.description} ${formatToCurrency(item.price)}`}
            selected={item.id === distributionID}
          />
        ))
      )}
    </Flex>
  );
};

export default DocumentsDistribution;
