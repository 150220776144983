import { FC, Ref, useEffect } from "react";
import { InboxOutlined } from "@ant-design/icons";
import {
  Form,
  Upload,
  Typography,
  UploadProps,
  UploadFile,
  notification,
} from "antd";
import { UploadRef } from "antd/lib/upload/Upload";
import { ACCEPTED_FILES } from "consts";
import { RcFile } from "types";
import { Storage } from "utils";

const { Dragger } = Upload;
const { Paragraph } = Typography;

interface FileUploadProps extends UploadProps {
  onRemove?: UploadProps["onRemove"];
  onUploadSuccess?: (file: RcFile) => void;
  required?: boolean;
  multiple?: boolean;
  name: string;
  fileID?: string;
  label?: string | React.ReactNode;
  getValueProps?: (v: any) => { value: any };
  maxCount?: number;
  innerRef?: Ref<UploadRef>;
  formItemClassName?: string;
}

const addThumbURL = (file: UploadFile) => {
  // new Chrome update prevents opening files via base64
  file.thumbUrl = URL.createObjectURL(file.originFileObj as File);
  return file;
};

const FileUpload: FC<FileUploadProps> = ({
  onRemove,
  required = false,
  multiple = false,
  fileID,
  name,
  label,
  getValueProps,
  maxCount,
  children,
  innerRef,
  formItemClassName,
  onUploadSuccess,
  ...rest
}) => {
  const formInstance = Form.useFormInstance();

  useEffect(() => {
    if (!fileID) {
      return;
    }

    formInstance.setFieldValue(name, [
      {
        status: "uploading",
      },
    ]);

    Storage.getURL({
      key: fileID,
    })
      .then(async ({ url }) => {
        const response = await fetch(url.toString());
        const blob = await response.blob();

        const file = new File([blob], fileID, {
          type: blob.type,
        });
        // add thumbUrl for displaying a picture
        // eslint-disable-next-line
        // @ts-ignore
        file.thumbUrl = url.toString();

        formInstance.setFieldValue(name, [file]);
      })
      .catch(() => {
        formInstance.setFieldValue(name, [
          {
            status: "error",
          },
        ]);
      });
  }, [fileID, formInstance, name]);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e.map(addThumbURL);
    }
    return e && e.fileList.map(addThumbURL);
  };

  const dragger = (
    <Dragger
      {...rest}
      ref={innerRef}
      multiple={multiple}
      accept={ACCEPTED_FILES}
      onRemove={onRemove}
      maxCount={maxCount}
      customRequest={({ onSuccess, file }) => {
        onSuccess?.(file);

        onUploadSuccess?.(file as RcFile);
      }}
      isImageUrl={(file) =>
        ["jpg", "jpeg", "png"].some(
          (format) => file.type?.indexOf(format) !== -1,
        )
      }
      beforeUpload={(file) => {
        const format = file.type.split("/")[1];

        if (file.size / 1024 / 1024 > 5) {
          notification.error({
            message: "This file is too big",
          });

          return Upload.LIST_IGNORE;
        }

        if (!/(pdf|doc|docx|jpg|jpeg|png|msword)$/g.test(format)) {
          notification.error({
            message: `Invalid file format ${format}`,
            description: `The following types are allowed only: ${ACCEPTED_FILES}`,
          });

          return Upload.LIST_IGNORE;
        }
      }}
    >
      {children || (
        <>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <Paragraph>Click or drag file to this area to upload</Paragraph>
          {multiple && (
            <Paragraph>Multiple files can be added to this Record</Paragraph>
          )}
        </>
      )}
    </Dragger>
  );

  return (
    <Form.Item
      name={name}
      label={label}
      className={formItemClassName}
      getValueProps={getValueProps}
      getValueFromEvent={normFile}
      valuePropName="fileList"
      rules={[
        {
          required,
          message: "Can't be blank",
        },
      ]}
    >
      {dragger}
    </Form.Item>
  );
};

export default FileUpload;
