import { FC, MutableRefObject } from "react";
import { Form, Input, InputRef, FormItemProps } from "antd";

interface InputTextProps extends FormItemProps {
  type?: "Password" | "TextArea" | "Search";
  placeholder?: string;
  label?: string;
  required?: boolean;
  isDuplicate?: boolean;
  autoFocus?: boolean;
  innerRef?: MutableRefObject<InputRef | null>;
}

const InputText: FC<InputTextProps> = ({
  type,
  name,
  isDuplicate,
  autoFocus,
  placeholder,
  rules = [],
  required,
  label,
  innerRef,
}) => {
  const I = type ? Input[type] : Input;

  return (
    <Form.Item
      label={label}
      name={name}
      normalize={(value) => value.replace(/\s{2,}/g, " ")}
      rules={[
        {
          required,
          message: "Can't be blank",
        },
        {
          validator() {
            return isDuplicate
              ? Promise.reject("This item already exists")
              : Promise.resolve();
          },
        },
        ...rules,
      ]}
    >
      <I
        ref={innerRef}
        placeholder={placeholder}
        autoComplete="new-password"
        autoFocus={autoFocus}
      />
    </Form.Item>
  );
};
export default InputText;
