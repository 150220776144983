import { FC } from "react";
import dayjs from "dayjs";
import { DATE_FORMAT } from "consts";
import { ExtendedDescriptions, StatusTag, InputCheckbox } from "components";
import { TabProps } from "../../types";

const Summary: FC<TabProps> = ({ company }) => {
  return (
    <ExtendedDescriptions
      items={[
        {
          label: "common.name",
          value: company.name,
        },
        {
          label: "common.jurisdiction",
          value: company.jurisdiction,
        },
        {
          label: "company.firstStep.selfManagedSuperFund",
          value: (
            <InputCheckbox
              formItem={false}
              checked={!!company.selfManagedSuperFund}
            />
          ),
        },
        {
          label: "company.firstStep.nameReserved",
          value: (
            <InputCheckbox formItem={false} checked={!!company.nameReserved} />
          ),
        },
        {
          label: "company.firstStep.nameIdentical",
          value: (
            <InputCheckbox formItem={false} checked={!!company.nameIdentical} />
          ),
        },
        {
          label: "common.client",
          value: company.client,
        },
        {
          label: "common.type",
          value: company.type,
        },
        {
          label: "company.acn",
          value: company.acn,
        },
        {
          label: "date.registeredAt",
          value:
            company.registeredAt &&
            dayjs(company.registeredAt).format(DATE_FORMAT),
        },
        {
          label: "common.status",
          value: <StatusTag id={company.statusID} name={company.status} />,
        },
      ]}
    />
  );
};

export default Summary;
