import { FC } from "react";
import { TabProps } from "../../types";
import { Flex } from "components";
import * as LC from "./components";

const Options: FC<TabProps> = ({ company }) => {
  return (
    <Flex gap={14} justify="space-around" wrap="wrap">
      <LC.OptionsCard
        title="Registed Agent Plan"
        description="$88.00 (12mth)"
        selected={company.registeredAgentPlan}
      />
      <LC.OptionsCard
        title="Domain Name"
        description="$0"
        selected={company.domainName}
      />
    </Flex>
  );
};

export default Options;
