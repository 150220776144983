import {
  useMutation,
  useQueryClient,
  DefaultError,
} from "@tanstack/react-query";
import { API } from "utils";
import { STAGE } from "consts";
import { operations, Types } from "./duck";
import { notification } from "antd";

const useMutationRequest = <TData = void, Variables = void>({
  apiName,
  method,
  path = "",
  options,
  onSuccess,
  onError,
  mutationKey,
  showSuccessMessage = true,
  invalidateQueries,
}: Types.MutationArgs<TData>) => {
  const queryClient = useQueryClient();

  const mutation = useMutation<TData, DefaultError, Variables>({
    mutationKey,
    onError,
    retry: STAGE !== "uat" && STAGE !== "prod" ? 2 : 0,
    onSuccess: async (newData) => {
      if (invalidateQueries) {
        await Promise.all(
          invalidateQueries.map((item) =>
            queryClient.invalidateQueries({
              queryKey: item,
            }),
          ),
        );
      }

      if (showSuccessMessage) {
        notification.success({
          message: "Success",
        });
      }

      onSuccess?.(newData);
    },
    mutationFn: async (variables) => {
      const operation = API[method]({
        apiName,
        path,
        options: {
          ...options,
          body: (variables as any) || undefined,
        },
      });

      return operations.queryFnWrapper<TData>(operation);
    },
  });

  return {
    ...mutation,
    queryClient,
  } as const;
};

export default useMutationRequest;
