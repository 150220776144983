export const COL_PROPS = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
};

export const ROW_GUTTER = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};

export const ACCEPTED_FILES = ".pdf, .doc, .docx, .jpg, .jpeg, .png, .msword";
