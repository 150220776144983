import { createSlice } from "@reduxjs/toolkit";
import { Types } from "./duck";

const initialState: Types.State = {
  loading: false,
};

const appState = createSlice({
  name: "appState",
  initialState,
  reducers: {},
});

export const { reducer, actions } = appState;
